import axiosInstance from "../../Api";

const baseUrl = deltax.apiEndPoint;
const businessProfileId = deltax.businessProfileId;

let ApiService = {
  savePromotion: promotionObject => {
    return axiosInstance.post(
      baseUrl + `businessProfiles/${businessProfileId}/promotions`,
      promotionObject
    );
  },
  patchPromotion: (promotionId, data) => {
    return axiosInstance.patch(
      baseUrl + `businessProfiles/${businessProfileId}/Promotions/${promotionId}`,
      JSON.stringify(JSON.stringify({ data }))
    );
  },
  updateCampaignConfig: (data) => {
    const url = `/App/CampaignLauncher/SaveMediaPlanCampaignConfiguration/${businessProfileId}`
    return axiosInstance.post(url, data)
  },
  getPromotions: (payload) => {
    let params = {};
    if (payload.promoStatus) {
      params.status = payload.promoStatus.join(",");
    }
    if (payload.pageNumber) {
      params.pageOffset = payload.pageNumber;
    }
    if (payload.limit) {
      params.pageSize = payload.limit;
    }
    if (payload.orderBy) {
      params.orderBy = payload.orderBy;
      if (payload.isDescending !== "undefined") {
        params.isDescending = payload.isDescending;
      }
    }
    if (payload.name) {
      params.name = payload.name;
    }
    if (payload.storeIds) {
      params.storeIds = payload.storeIds;
    }
    if (payload.promotionIds) {
      params.promotionIds = payload.promotionIds;
    }
    if (payload.isEligibleForLaunch) {
      params.isEligibleForLaunch = payload.isEligibleForLaunch;
    }
    if (payload.includeOrganicPromotions !== "undefined") {
      params.isOrganic = payload.includeOrganicPromotions;
    }
    if (payload.startDate) {
      params.startDate = payload.startDate;
    }
    if (payload.endDate) {
      params.endDate = payload.endDate;
    }
    if (payload.hasOwnProperty("includeStores")) {
      params.includeStores = payload.includeStores;
    }

    return axiosInstance.get(
      baseUrl + `businessProfiles/${businessProfileId}/Promotions`, {
        params
      }
    ).then((resp) => {
      return resp.data.data;
    });
  },
  getPromotion: async function (id) {
    let response = await axiosInstance.get(
      baseUrl + `businessProfiles/${businessProfileId}/Promotions/${id}`);
    return response.data.data;
  },
  getAccounts: () => {
    return axiosInstance.get(
      baseUrl + `businessProfiles/${businessProfileId}/Accounts`
    );
  },
  getStoreLeadForms: () => {
    return axiosInstance.get(
      baseUrl + `businessProfiles/${businessProfileId}/StoreLeadForms`
    );
  },
  getTargets: () => {
    return axiosInstance.get(
      baseUrl + `businessProfiles/${businessProfileId}/Targets`
    );
  },
  getStores: async (isDefault, storeCodes, advertiserIds, includeDefault, storeIds) => {
    let params = {}
    if (isDefault) {
      params.isDefault = true;
    } if (storeCodes) {
      params.storeCodes = storeCodes;
    } if (advertiserIds) {
      params.advertiserIds = advertiserIds;
    } if (includeDefault) {
      params.includeDefault = true;
    } if (storeIds) {
      params.storeIds = storeIds;
    }
    let response = await axiosInstance.get(
      baseUrl + `businessProfiles/${businessProfileId}/Stores`, {
        params
      }
    );
    return response.data.data;
  },
  getProducts: (pageSize = 100) => {
    return axiosInstance.get(
      baseUrl +
        `businessProfiles/${businessProfileId}` +
        `/Products?fields=Title&fields=Id&fields=ImageLink&pageSize=${pageSize}`
    );
  },
  getTargetClusters: () => {
    return axiosInstance.get(
      baseUrl + `businessProfiles/${businessProfileId}/TargetClusters`
    );
  },
  getPromotionInsights: (promotionIds) => {
    let url = baseUrl + `businessProfiles/${businessProfileId}/Promotions/insights`;
    let params = {
      promotionIds: promotionIds.join(',')
    }
    return axiosInstance.get(url, {
      params: params
    });
  },
  disablePromotion (promotionId) {
    return axiosInstance.post(
      baseUrl +
        `businessProfiles/${businessProfileId}/promotions/${promotionId}/disable`
    );
  },
  getDataParameters () {
    return axiosInstance.get(`${baseUrl}businessProfiles/${businessProfileId}/budgetcenter/DataParameters`);
  },
  async publishStores (promotionId, promoCampaign) {
    let url = `${baseUrl}businessProfiles/${businessProfileId}/promotions/${promotionId}/launch`
    let response = await axiosInstance.post(url, promoCampaign);
    return response.data.data;
  },
  getImages (businessProfileId, businessProfileSearchEngineId, imageIds) {
    let url = `${baseUrl}businessProfiles/${businessProfileId}/accounts/${businessProfileSearchEngineId}/images`;
    let params = {
      ids: imageIds.join(',')
    }
    return axiosInstance.get(url, {
      params: params
    });
  },
  getVideos (businessProfileId, businessProfileSearchEngineId, videoIds) {
    let url = `${baseUrl}businessProfiles/${businessProfileId}/accounts/${businessProfileSearchEngineId}/videos`;
    let params = {
      ids: videoIds.join(',')
    }
    return axiosInstance.get(url, {
      params: params
    });
  },
  async getBusinessProfile () {
    let response = await axiosInstance.get(`${baseUrl}businessProfiles/${deltax.businessProfileId}`);
    return response.data.data;
  },
  async saveCampaignLauncherConfiguration (clcConfig) {
    let response = await axiosInstance.post(`${baseUrl}businessProfiles/${deltax.businessProfileId}/CampaignLauncherConfigurations`, clcConfig);
    return response.data.data;
  },
  async getStoreInsights (storeIds) {
    let url = baseUrl + `businessProfiles/${businessProfileId}/Stores/insights`;
    let params = {
      storeids: storeIds
    }
    let response = await axiosInstance.get(url, {
      params: params
    });
    return response.data.data;
  },
  async getLabels (filter) {
    let url = baseUrl + `businessProfiles/${businessProfileId}/labels?${filter}`;
    let response = await axiosInstance.get(url);
    return response.data;
  },
  async getLabelsTypesById (labelIds) {
    let url = baseUrl + `businessProfiles/${businessProfileId}/labelTypes`;
    let params = {
      ids: labelIds.join()
    }
    let response = await axiosInstance.get(url, {
      params: params
    });
    return response.data.result;
  },
  async getStoreConfiguration (creativeJson, promotionId, storeId) {
    let requestBody = {
      creativeJson
    };
    if (storeId) {
      requestBody.storeId = storeId;
    }
    let response = await axiosInstance.post(`${baseUrl}businessprofiles/${businessProfileId}/promotions/${promotionId}/storeConfiguration`, requestBody);
    return response.data.data
  },
  async getVideosByIds (bpseId, videoIds) {
    var response = await axiosInstance.get(`${baseUrl}businessProfiles/${businessProfileId}/accounts/${bpseId}/videos`, {
      params: {
        ids: videoIds
      }
    });
    return response.data.data;
  },
  async getPage (bpseId, pageId) {
    let response = await axiosInstance.get(`${baseUrl}businessprofiles/${businessProfileId}/accounts/${bpseId}/live/pages/${pageId}`);
    return response.data.data;
  },
  async getContentTypes (urls) {
    let response = await axiosInstance.get(`${baseUrl}businessprofiles/${businessProfileId}/stores/assets/contentTypes`, {
      params: {
        assetUrls: urls
      }
    });
    return response.data.data;
  },
  async getPromotionVersions (promotionIds, latest = false) {
    if (promotionIds.length > 0) {
      let response = await axiosInstance.get(`${baseUrl}businessProfiles/${businessProfileId}/promotionVersions?promotionIds=${promotionIds.join(",")}&latest=${latest}`);
      return response.data;
    }
  },
  async patchPromotionVersion (promotionVersionId, data) {
    await axiosInstance.patch(`${baseUrl}businessProfiles/${businessProfileId}/promotionVersions/${promotionVersionId}`, `${JSON.stringify(JSON.stringify({ data: data }))}`)
  },
  async postPromotionVersion (promotionId, data) {
    await axiosInstance.post(`${baseUrl}businessProfiles/${businessProfileId}/promotions/${promotionId}/promotionVersions`, data);
  },
  async getCurrency (currencyId) {
    let response = await axiosInstance.get(`${baseUrl}businessProfiles/${deltax.businessProfileId}/currencies/${currencyId}`);
    return response.data.data;
  }
};

export default ApiService;
