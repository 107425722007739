import axiosInstance from '../../../Api'

export class ApiService {
  getMediaPlanObjectives () {
    const mediaPlanObjectivesUrl = deltax.apiBaseUrl
      .concat(`businessProfiles/${deltax.businessProfileId}/`)
      .concat(`budgetcenter/Objectives`)
    const promise = axiosInstance.get(mediaPlanObjectivesUrl);
    return promise;
  }

  getLineItemTypes () {
    const lineItemTypesUrl = deltax.apiBaseUrl
      .concat(`businessProfiles/${deltax.businessProfileId}/`)
      .concat(`budgetcenter/LineItemTypes`)
    const promise = axiosInstance.get(lineItemTypesUrl);
    return promise;
  }

  getLabels () {
    const labelurl = deltax.apiBaseUrl
      .concat(`businessProfiles/${deltax.businessProfileId}/`)
      .concat(`Labels`)
    const promise = axiosInstance.get(labelurl);
    return promise;
  }

  getLabelTypes () {
    const labelTypesurl = deltax.apiBaseUrl
      .concat(`businessProfiles/${deltax.businessProfileId}/`)
      .concat(`LabelTypes`)
    const promise = axiosInstance.get(labelTypesurl);
    return promise;
  }
  getLineItemPublishers () {
    const lineItemPublishersUrl = deltax.apiBaseUrl
      .concat(`businessProfiles/${deltax.businessProfileId}/`)
      .concat(`budgetcenter/Publishers`)
    const promise = axiosInstance({
      method: 'GET',
      url: lineItemPublishersUrl,
      params: {
        status: '1'
      }
    })
    return promise;
  }

  getLineItemDataParameters () {
    const lineItemDataParametersUrl = deltax.apiBaseUrl
      .concat(`businessProfiles/${deltax.businessProfileId}/`)
      .concat(`budgetcenter/DataParameters`)
    const promise = axiosInstance.get(lineItemDataParametersUrl);
    return promise;
  }

  getDataParameterPricingTypeMapping () {
    const DataParameterPricingTypeMappingUrl = deltax.apiBaseUrl
      .concat(`businessProfiles/${deltax.businessProfileId}/`)
      .concat(`budgetcenter/CostOptions`)
    const promise = axiosInstance.get(DataParameterPricingTypeMappingUrl);
    return promise;
  }

  updateMediaPlanDetails (mediaPlanRequest) {
    const saveMediaPlanDetailsUrl = deltax.apiBaseUrl
      .concat(`businessProfiles/${deltax.businessProfileId}/`)
      .concat(`MediaPlans`)
    const promise = axiosInstance({
      method: 'POST',
      url: saveMediaPlanDetailsUrl,
      data: mediaPlanRequest
    });
    return promise;
  }

  saveBulkMediaPlan (bulkMediaPlan) {
    const saveBulkMediaPlanUrl = deltax.apiBaseUrl
      .concat(`businessProfiles/${deltax.businessProfileId}/`)
      .concat(`MediaPlans`)
    const promise = axiosInstance({
      method: 'POST',
      url: saveBulkMediaPlanUrl,
      data: bulkMediaPlan
    });
    return promise;
  }

  getMediaPlanDataById (mediaPlanId) {
    const getMediaPlanDataByIdUrl = deltax.apiBaseUrl
      .concat(`businessProfiles/${deltax.businessProfileId}/`)
      .concat(`MediaPlans/MediaPlan?mediaPlanId=${mediaPlanId}`)
    const promise = axiosInstance.get(getMediaPlanDataByIdUrl);
    return promise;
  }

  getMediaPlanVersionDataById = function (mediaPlanId, versionId) {
    const getMediaPlanChildEntitiesUrl = deltaXApiUrl.mediaPlans.getChildEntities
      .replace('{businessProfileId}', deltax.businessProfileId)
      .concat(`?mediaPlanId=${mediaPlanId}`)
      .concat(`&mediaPlanVersionId=${versionId}`)
    return axiosInstance.get(getMediaPlanChildEntitiesUrl);
  }

  async getPhasesByBriefId (briefIds) {
    let url = deltax.apiBaseUrl
      .concat(`businessProfiles/${deltax.businessProfileId}/`)
      .concat(`BriefPhases`);
    let res = await axiosInstance.get(url, {
      params: {
        briefIds: briefIds
      } });
    return res.data.data;
  }

  async getPhaseById (id) {
    let url = deltax.apiBaseUrl
      .concat(`businessProfiles/${deltax.businessProfileId}/`)
      .concat(`BriefPhases/${id}`);
    let res = await axiosInstance.get(url);
    return res.data.data;
  }

  syncMediaPlan (mediaPlanId) {
    const promise = axiosInstance({
      url: deltax.syncMediaPlanData,
      method: 'POST',
      dataType: "json",
      data: { mediaPlanId: mediaPlanId }
    });
    return promise;
  }

  getAllLineItems () {
    const getAllLineItemsURL = deltax.getLineItems
    var params = '?mediaPlanIds=' + 'all'
    const promise = axiosInstance.get(getAllLineItemsURL + params)
    return promise;
  }

  getLineItemInformationById (lineItemIds, retainLineItemCampaignLink) {
    const promise = axiosInstance({
      url: deltax.getLineItemInformationUrl,
      method: 'POST',
      contentType: "application/json; charset=utf-8",
      data: JSON.stringify({
        lineItemIds: lineItemIds,
        retainLineItemCampaignLink: retainLineItemCampaignLink
      })
    });
    return promise;
  }
  getEDSReferenceKeys () {
    const getEDSReferenceKeysUrl = deltax.apiBaseUrl
      .concat(`Eds?businessProfileId=${deltax.businessProfileId}`)
    const promise = axiosInstance.get(getEDSReferenceKeysUrl);
    return promise;
  }

  getApprochNotes (mediaPlanId) {
    const approachNotesUrl = deltax.apiBaseUrl
      .concat(`businessProfiles/${deltax.businessProfileId}/`)
      .concat(`ApproachNote?mediaPlanId=${mediaPlanId}`)
    const promise = axiosInstance.get(approachNotesUrl);
    return promise;
  }

  saveApproachNotes (approachNotesObj) {
    const saveApproachNotesUrl = deltax.apiBaseUrl
      .concat(`businessProfiles/${deltax.businessProfileId}/`)
      .concat(`ApproachNote`)
    const promise = axiosInstance({
      url: saveApproachNotesUrl,
      method: 'POST',
      data: approachNotesObj
    });
    return promise;
  }

  editApproachNotes (approachNotesId, approachNotesObj) {
    const saveApproachNotesUrl = deltax.apiBaseUrl
      .concat(`businessProfiles/${deltax.businessProfileId}/`)
      .concat(`ApproachNote/${approachNotesId}`)
    const promise = axiosInstance({
      method: 'PUT',
      url: saveApproachNotesUrl,
      data: approachNotesObj
    });
    return promise;
  }

  uploadApprochNotesAttachments (approachNotesId, attachment) {
    const approachNotesUrl = deltax.apiBaseUrl
      .concat(`businessProfiles/${deltax.businessProfileId}/`)
      .concat(`ApproachNote/${approachNotesId}/Upload`)
    const promise = axiosInstance({
      url: approachNotesUrl,
      method: 'POST',
      data: attachment,
      cache: false,
      processData: false,
      contentType: false
    });
    return promise;
  }

  deleteApproachNoteAttachments (attachments) {
    const deleteApproachNotesUrl = deltax.apiBaseUrl
      .concat(`businessProfiles/${deltax.businessProfileId}/`)
      .concat(`ApproachNote`)
    const promise = axiosInstance({
      method: 'DELETE',
      url: deleteApproachNotesUrl,
      data: attachments
    });
    return promise;
  }

  saveBriefNote (briefObj) {
    const saveBriefNoteUrl = deltax.apiBaseUrl
      .concat(`businessProfiles/${deltax.businessProfileId}/`)
      .concat(`Brief`)
    const promise = axiosInstance({
      url: saveBriefNoteUrl,
      method: 'POST',
      data: briefObj
    });
    return promise;
  }

  getBudgetCenterConfiguration () {
    const budgetCenterConfigUrl = deltax.apiBaseUrl
      .concat(`businessProfiles/${deltax.businessProfileId}/`)
      .concat(`budgetcenter/BudgetCenterConfiguration`)
    const promise = axiosInstance.get(budgetCenterConfigUrl);
    return promise;
  }

  updateBriefDescription (briefId, properties) {
    const updateBriefDescriptionUrl = deltax.apiBaseUrl
      .concat(`businessProfiles/${deltax.businessProfileId}/`)
      .concat(`Brief/${briefId}`)
    const promise = axiosInstance({
      method: 'patch',
      url: updateBriefDescriptionUrl,
      data: properties
    })
    return promise
  }
  saveLinkedEntities (entitiesLinked) {
    const saveLinkedEntitiesUrl = deltax.apiBaseUrl
      .concat(`businessProfiles/${deltax.businessProfileId}/`)
      .concat(`LineItems/${entitiesLinked.lineItemId}/entities`)
    const promise = axiosInstance({
      method: 'POST',
      url: saveLinkedEntitiesUrl,
      contentType: "application/json",
      data: JSON.stringify({
        entityTypeId: entitiesLinked.entityTypeId,
        LineItemEntities: entitiesLinked.lineItemEntityRequest
      })
    });
    return promise;
  }

  updateVisibleAttributes (properties) {
    const updateVisibleAttributesUrl = deltax.apiBaseUrl
      .concat(`businessProfiles/${deltax.businessProfileId}/`)
      .concat(`BudgetCenter/BudgetCenterConfiguration`)
    const promise = axiosInstance({
      method: 'patch',
      url: updateVisibleAttributesUrl,
      data: properties
    })
    return promise
  }
  getBriefs () {
    const getBriefs = deltax.apiBaseUrl
      .concat(`businessProfiles/${deltax.businessProfileId}/`)
      .concat(`Brief?status=Planning,Upcoming,Live,Completed`)
    const promise = axiosInstance.get(getBriefs);
    return promise;
  }
  getLineItemRecommedations (startDate, endDate, budget, objective) {
    const getLineItemRecommendationsUrl = deltax.apiBaseUrl
      .concat(`businessProfiles/${deltax.businessProfileId}/`)
      .concat(`LineItems/recommendations?startDate=${startDate}&`)
      .concat(`endDate=${endDate}&budget=${budget}&objective=${objective}`)
    const promise = axiosInstance.get(getLineItemRecommendationsUrl)
    return promise
  }
  getPortalDetails () {
    const getPortalDetails = deltax.apiBaseUrl
      .concat(`businessprofile/${deltax.businessProfileId}/portals?type=default`)
    const promise = axiosInstance.get(getPortalDetails)
    return promise
  }
  getDivisions () {
    const getDivisionDetails = deltax.apiBaseUrl
      .concat(`businessprofiles/${deltax.businessProfileId}/divisions`)
    const promise = axiosInstance.get(getDivisionDetails)
    return promise
  }
  getNomenclatureSettingsData () {
    const url = deltax.apiBaseUrl
      .concat(`businessProfiles/${deltax.businessProfileId}`)
      .concat(`/NomenclatureSettings`)
    const promise = axiosInstance.get(url);
    return promise;
  }

  downloadNomenclatureReport (mediaPlanId) {
    var url = deltax.downloadNomenclatureReportUrl + `?id=${deltax.businessProfileId}&mediaPlanId=${mediaPlanId}&type=8&endDate=January+10%2C2021`;
    $('#nomenclature-report-spinner').removeClass('fa-download').addClass('fa-spinner fa-spin');
    $.fileDownload(url, {
      timeout: 180,
      httpMethod: 'get'
    }).done(function () {
      $('#nomenclature-report-spinner').removeClass('fa-spinner fa-spin').addClass('fa-download');
    }).fail(function () {
      alert("Nomenclature Setup File download failed");
      $('#nomenclature-report-spinner').removeClass('fa-spinner fa-spin').addClass('fa-download');
    });
  }
  getStaticLineItemPublishers (portalId) {
    const url = deltax.apiBaseUrl
      .concat(`portal/${portalId}/`)
      .concat(`publisher`)
    const promise = axiosInstance({
      method: 'GET',
      url: url,
      params: {
        status: '1'
      }
    })
    return promise;
  }
  getBusinessProfile () {
    // eslint-disable-next-line no-undef
    const getBusinessProfileDetails = secretKeyParams.apiBaseUrl
      .concat(`businessprofiles/${deltax.businessProfileId}`)
    const promise = axiosInstance.get(getBusinessProfileDetails)
    return promise
  }
  getCustomFieldsAlias () {
    const getCustomFieldsAlias = deltaXApiUrl.budgetCenter.getCustomFieldsAlias
      .replace('{businessProfileId}', deltax.businessProfileId)
    const promise = axiosInstance.get(getCustomFieldsAlias)
    return promise
  }
}
